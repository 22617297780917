
import {debounce} from "@/helpers/debounceHelper.js";
import ShopPageTitle from "@/layouts/shop/components/pages/ShopPageTitle.vue";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner.vue";
import DeliveryTimeSlotPreviewSlot from "@/layouts/shop/components/help/delivery/DeliveryTimeSlotPreviewSlot.vue";

export default {
  name: "DeliveryTimeSlotPreview",
  components: {DeliveryTimeSlotPreviewSlot, LoadingSpinner, ShopPageTitle},
  data() {
    return {
      fetchedTimeslots: [],
      loading: true,

      zoneDisplayed: null,
    }
  },
  async fetch() {
    await this.fetchTimeSlots();
  },
  props: {
    zone: {type: Number},
    postalCode: {type: String}
  },
  watch: {
    getZoneAndPostalCode: debounce(function () {
      return this.fetchTimeSlots();
    }, 300),
  },
  methods: {
    fetchTimeSlots() {
      this.loading = true;

      if (this.zone === null && this.postalCode === null) {
        this.$emit('update:zone', 1);
        return;
      }

      return this.$deliveryTimeslotRepository.getTimeslots(
        this.zone,
        this.postalCode
      ).then((res) => {
        this.fetchedTimeslots = res.data;
        this.zoneDisplayed = res.data[0]?.zone || '?';

        this.$nextTick(() => {
          this.loading = false;
        });
      });
    },

    getTimeSlotsForDate(date) {
      return this.fetchedTimeslots.filter(t => {
        return this.$dayjs(t.startDate).isSame(date, 'day');
      }).sort((a, b) => b.isRecommended - a.isRecommended);
    },
  },
  computed: {
    getZoneAndPostalCode() {
      return this.zone + '-' + this.postalCode;
    },

    getDeliveryDays() {
      let days = [];
      let from = this.$dayjs();
      const maxCurrentDateHour = this.$dayjs().set('hour', 22).set('minute', 30);

      // do not display current day if is past 10:30 PM
      if (from.isAfter(maxCurrentDateHour)) {
        from = from.add(1, 'day');

      }
      const to = this.$dayjs(from).add(6, 'day');

      // Generates a date range (Tomorrow + 6 days)
      let currentDate = this.$dayjs(from);

      while (currentDate.isBefore(to) || currentDate.isSame(to)) {
        currentDate = currentDate.add(1, 'day');

        // skip sunday
        // if (currentDate.day() === 0) {
        //   continue;
        // }

        days.push(currentDate.format('YYYY-MM-DD'));
      }

      return days;
    },
  },
}
