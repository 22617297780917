
import CheckoutTimeSlot from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeSlot.vue";
import CheckoutTimeSlotDay from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeSlotDay.vue";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner.vue";
import ShopPageTitle from "@/layouts/shop/components/pages/ShopPageTitle.vue";
import localeMixin from "@/mixins/localeMixin.js";
import CheckoutTimeslotUnavailableDateBox
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeslotUnavailableDateBox.vue";
import CheckoutTimeslotSelectorUnavailableDateModal
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/unavailableModal/CheckoutTimeslotSelectorUnavailableDateModal.vue";

export default {
  name: "CheckoutTimeslotSelector",
  components: {
    CheckoutTimeslotSelectorUnavailableDateModal,
    CheckoutTimeslotUnavailableDateBox, ShopPageTitle, LoadingSpinner, CheckoutTimeSlotDay, CheckoutTimeSlot},
  mixins: [localeMixin],
  data() {
    return {
      selectedDay: {
        date: null,
        items: []
      }
    }
  },
  created() {
    this.syncDayjsLocale();
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    // Returns timeslots for the selected day and orders them so that recommended timeslots come first
    getTimeSlotsForDay(day) {
      if (!this.getTimeslots) {
        return [];
      }

      return this.getTimeslots.filter(t => {
        return this.$dayjs(t.startDate).isSame(day, 'day');
      }).sort((a, b) => b.isRecommended - a.isRecommended);
    },
    isDayAvailable(date) {
      return this.getUnavailableDays.find(d => d.date === date) === undefined;
    },
    getUnavailableItemsForDay(date) {
      return this.getUnavailableDays.find(d => d.date === date)?.items || [];
    },
    openUnavailableModal(date) {
      const items = this.getUnavailableItemsForDay(date);

      if(!items) {
        return false;
      }

      this.selectedDay.date = date;
      this.selectedDay.items = items;

      this.$nextTick(() => {
        this.$refs.unavailableModal.openModal();
      });
    },
    // User has resolved all the problems for one day.
    // Reload unavailable timeslots.
    onProblemsForDayResolved() {
      this.selectedDay.date = null;
      this.selectedDay.items = [];
    },
    onItemIdResolved(itemId) {
      this.selectedDay.items = this.selectedDay.items.filter(i => i.productId !== itemId);
    }
  },
  computed: {
    /**
     * Returns the days when delivery is possible.
     *
     * @returns {*}
     */
    getDeliveryDays() {
      let days = [];
      let from = this.$dayjs();
      const maxCurrentDateHour = this.$dayjs().set('hour', 22).set('minute', 30);

      // do not display current day if is past 10:30 PM
      if (from.isAfter(maxCurrentDateHour)) {
        from = from.add(1, 'day');
      }
      const to = this.$dayjs(from).add(6, 'day');

      // Generates a date range (Tomorrow + 6 days)
      let currentDate = this.$dayjs(from);

      while (currentDate.isBefore(to) || currentDate.isSame(to)) {
        currentDate = currentDate.add(1, 'day');

        // skip sunday
        if (currentDate.day() === 0) {
          continue;
        }

        days.push(currentDate.format('YYYY-MM-DD'));
      }

      return days;
    },
    selectedTimeslotId: {
      get() {
        return this.$store.getters['checkout/getSelectedTimeslotId'];
      },
      set(id) {
        this.$store.commit('checkout/setSelectedTimeslotId', id);
      }
    },
    getTimeslots() {
      return this.$store.getters['checkout/getTimeslots'];
    },
    getUnavailableDays() {
      return this.$store.getters["checkout/getUnavailableTimeslots"];
    }
  },
}
