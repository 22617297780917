import { findByKey } from "vuex-intern";


export const state = () => ({
  items: [],
  itemCount: 0,
  cartQuantities: [],
  totals: null,
  hasError: false,
  cartLoading: false,
  packagingGroups: []
})


let setQuantitiesInStorage =  function (quantities) {
  window._localStorage.setItem('cartQuantities', JSON.stringify(quantities));
}

export const mutations = {
  initCartQuantities(state) {
    if(process.client) {
      const local = window._localStorage.getItem('cartQuantities');
      state.cartQuantities = local ? JSON.parse(local) : [];
    }
  },

  setCartData(state, { items, itemCount, totals, hasError, packagingGroups }) {
    state.items = items;
    state.itemCount = itemCount;
    state.totals = totals;
    state.hasError = hasError;
    state.packagingGroups = packagingGroups;
  },
  /**
   * Clear the cart and remove all items/quantities from it.
   *
   * @param state
   */
  resetCart(state) {
    window._localStorage.setItem('cartQuantities', JSON.stringify([]));
    state.cartQuantities = [];
    state.items = [];
    state.itemCount = 0;
    state.totals = null;
    state.hasError = false;
    state.cartLoading = false;
    state.packagingGroups = [];
  },

  setCartLoading(state, boolean) {
    state.cartLoading = boolean;
  },

  setCartItemQuantity(state, {itemId, quantity}) {
    if(quantity === 0) {
      state.cartQuantities = state.cartQuantities.filter(q => q.itemId !== itemId);
      setQuantitiesInStorage(state.cartQuantities);
      return;
    }

    let isItemInCart = state.cartQuantities.find(q => q.itemId === itemId);
    if(isItemInCart) {
      state.cartQuantities.find(q => q.itemId === itemId).quantity = quantity;
    } else {
      state.cartQuantities.unshift({
        itemId, quantity
      })
    }
    setQuantitiesInStorage(state.cartQuantities);
  },
}

export const actions = {
  async setCartItemQuantity({commit, dispatch}, {itemId, quantity}) {
    commit('setCartLoading', true);
    commit('setCartItemQuantity', {itemId, quantity});

    await dispatch('syncQuantitiesWithBackend');
  },

  async syncQuantitiesWithBackend({state, commit}) {
    let quantities = state.cartQuantities.map(q => {
      return {
        productId: q.itemId,
        quantity: q.quantity
      }
    });

    commit('setCartLoading', true);
    return await this.$cartRepository.syncCartFromSession(quantities).then((res) => {
      commit('setCartData', {
        items: res.data.items,
        itemCount: res.data.itemCount,
        totals: res.data.total,
        hasError: res.data.hasError,
        packagingGroups: res.data.packages,
      });
    }).finally(() => commit('setCartLoading', false));
  },

  clearItems({commit, dispatch}) {
    commit('resetCart');
    dispatch('syncQuantitiesWithBackend');
  }
};


export const getters = {
  getItems: state => state.items,
  getItemsWithoutFeesAndCampaigns: state => {
    return state.items.filter(item => {
      return item.type !== "Fee" && item.type !== "Gift" && item.type !== "Fixed" && item.type !== "Discount";
    });
  },
  getFees: state => state.items.filter(item => item.type === "Fee"),
  getCampaigns: state => state.items.filter(item => item.type === "Gift" || item.type === "Discount" || item.type === "Fixed"),
  getItemCount: state => state.itemCount,
  getProductItemsMapped: state => state.items
    .filter(i => i.type.toLowerCase() === "product")
    .map(p => ({
    productId: p.id,
    quantity: p.quantity
  })),
  getItemByKey: findByKey('items', 'id'),
  getQuantities: state => state.cartQuantities,
  getItemQuantityInCart: (state) => (itemId) => {
    return state.cartQuantities.find(i => i.itemId === itemId)?.quantity ?? 0;
  },


  isCartEmpty: state => !state.items.length,
  getTotals: state => state.totals,
  getHasCartErrors: state => state.hasError,
  isCartLoading: state => state.cartLoading,
};
