
import XmasLogoSvg from "@/layouts/shop/svgs/XmasLogoSvg.vue";

export default {
  name: "LogoSvg",
  components: {XmasLogoSvg},
  props: {
    useFullHeight: {
      type: Boolean,
      default: true
    },
    xmas: {
      type: Boolean,
      default: false
    }
  }
}
