// ==========================================
// This global mixin reacts to various events
// emitted throughout the application.
//
// Its currently only used to send Sendinblue tracking data.
// ==========================================

// Cart has been cleared
const onCartDeleted = (vm) => {
    if(typeof sendinblue !== "undefined") {
      sendinblue?.track('cart_deleted');
    }
};

// Cart has been updated
const onCartUpdated = (vm) => {
    const items = vm.$store.getters["cart/getItems"]
        .slice(0, 5) // Limit to 5 last added items
        .map(i => {
        // To check, should we calculated price of Base Item + all Reference items in case there are discounts?
        // However: Beware, since we send the price while updating the cart, it could be different already when
        // abandoned cart Email is sent.
        // Should we even send price at all?
        return {
            name: i.name,
            quantity: i.quantity,
            imageUrl: vm.$getUrlOfImage(i.image, "medium"),
            price: i.price.amount / 100
        }
    });

    if (items.length === 0) {
      onCartDeleted(vm);
    } else {
      if(typeof sendinblue !== "undefined") {
        sendinblue.track('cart_updated', null, {
          data: {
            total: vm.$store.getters["cart/getTotalAmount"],
            currency: "EUR",
            items: items
          }
        });
      }
    }

};


// Register all events.
const events = [
    {name: 'cart_deleted', executeFunc: onCartDeleted},
    {name: 'cart_updated', executeFunc: onCartUpdated},
];


export default {
    created() {
        const vm = this;
        // Add Event Listeners
        events.forEach(event => {
            vm.$nuxt.$on(event.name, () => event.executeFunc(vm));
        });
    },
    beforeDestroy() {
        const vm = this;
        // Destroy event listeners.
        events.forEach(e => {
            vm.$nuxt.$off(e.name);
        })
    }
}
