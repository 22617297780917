
import ShopSidebarCategory from "./ShopSidebarCategory";
import { mapGetters } from "vuex";
import ShopSidebarSimpleLink from "@/layouts/shop/partials/sidebar/ShopSidebarSimpleLink";
import ShopMobileCategory from "@/layouts/shop/partials/mobile/category/ShopMobileCategory.vue";

export default {
  name: "ShopSidebarMenu",
  components: {ShopMobileCategory, ShopSidebarSimpleLink, ShopSidebarCategory },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    categories: []
  }),
  mounted() {
    console.log(this.$config.luxuryCategoryId);
  },
  computed: {
    ...mapGetters('sidebar', ['getCategories']),
    // Returns all categories, but with luxury removed.
    getCategoriesWithoutSeperates() {
      if(!this.hasLuxuryCategory) {
        return this.getCategories;
      }

      return this.getCategories.filter(category => {
        return category.id !== Number(this.$config.luxuryCategoryId);
      })
    },
    hasLuxuryCategory() {
      if(!this.$config.luxuryCategoryId) {
        return false;
      }

      return this.getCategories.find(category => category.id === Number(this.$config.luxuryCategoryId)) !== undefined;
    },
    getLuxuryCategory() {
      if(!this.hasLuxuryCategory) {
        return null;
      }

      return this.getCategories.find(category => category.id === Number(this.$config.luxuryCategoryId));
    }
  },
}
